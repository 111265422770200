import { customElement, children, TaskQueue, autoinject, bindable, bindingMode } from 'aurelia-framework';
import { Step } from './step';

@autoinject
@customElement('stepper')
export class Stepper {
    @children('step') steps: Step[] = [];
    activeTab: Step;
    taskQueue: TaskQueue;
    @bindable page: string;

    constructor(taskQueue: TaskQueue) {
        this.taskQueue = taskQueue;
    }

    pageChanged(newValue) {
        if (this.steps.length === 0) {
            return;
        }

        let resolve = this.steps.find((x) => x.hash === newValue);

        if (resolve !== null) {
            this.activateTab(resolve);
        }
    }

    // click(tab: Step, event) {
    //     event.stopPropagation();
    //     this.activate(tab);
    // }

    private activateTab(tab: Step) {
        if (this.activeTab) {
            this.activeTab.active = false;
        }

        let i = 0;

        for (; i < this.steps.length + 1; ++i) {
            this.steps[i].completed = true;
            // this.steps[i].active = true;

            if (this.steps[i] === tab) {
                i += 1;
                break;
            }
        }

        for (; i < this.steps.length; ++i) {
            this.steps[i].completed = false;
        }

        tab.active = true;
        this.activeTab = tab;
    }

    attached() {
        this.pageChanged(this.page);
    }
}
