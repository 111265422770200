import { bindable, customElement } from 'aurelia-framework';

@customElement('step')
export class Step {
    element: any;
    @bindable title: string;
    @bindable hash: string;
    @bindable active: boolean;
    @bindable completed: boolean;
}
