import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { HttpClient, json } from 'aurelia-fetch-client';
import './transaction-history.scss';
import { EventAggregator } from 'aurelia-event-aggregator';
import { NumbersValueConverter } from 'numbers';
import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'error/error-service';
import { TransactionDialog } from './transaction-dialog/transaction-dialog';
import { IPagedController } from 'components/pager/pager';

@autoinject
export class TransactionHistory implements Slice, Over, IPagedController {
    @bindable customerId: string;
    httpClient: HttpClient;
    canvas: HTMLCanvasElement;
    hover: HTMLElement;
    payload: Payload;
    line: Line | null = new Line();
    drag: DragBase = new DragNone(this.line, this);
    height: number = 100;
    width: number;
    rect: Rect | null = null;
    items: Sample[] = [];
    filtered: Sample[] = [];
    sum: number = 0;
    sum2: number = 0;
    avr: number = 0;
    avr2: number = 0;
    count: number = 0;
    y1: number = 0;
    y2: number = 0;
    active: Sample | null = null;
    min: number = 0;
    scale: number = 1;
    guides: Guide[] = [];
    eventAggregator: EventAggregator;
    periods: Pair<TimeSeriesPeriod>[] = [];
    selectedPeriod: TimeSeriesPeriod = TimeSeriesPeriod.OneYear;
    transactions: Transaction[] = [];
    windowFromTo: WindowFromTo = new WindowFromTo();
    dialogService: DialogService;
    errorService: ErrorService;
    page: number = 1;
    of: number = 1;
    total: number = 0;
    columns: Column[] = [];
    showHover: boolean = false;
    resolution: number = 1;
    configuration: Configuration;
    offsetGraph: number = 75;
    cursorX: number = 0;
    cursorY: number = 0;
    inbound: string = '0,0';
    outbound: string = '0,0';
    style: GraphStyle = GraphStyle.Bar;
    styles: Chart[] = [new Chart(GraphStyle.Bar, 'bar-chart-line-fill'), new Chart(GraphStyle.Line, 'graph-up')];
    margin: number = 10;
    unique: number = Math.random();
    subscription: { dispose: () => void };

    constructor(
        httpClient: HttpClient,
        eventAggregator: EventAggregator,
        dialogService: DialogService,
        errorService: ErrorService,
    ) {
        this.httpClient = httpClient;
        this.eventAggregator = eventAggregator;
        this.periods = [
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.Today, '1D'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.OneWeek, '1W'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.OneMonth, '1M'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.ThreeMonths, '3M'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.OneYear, '1Y'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.ThreeYears, '3Y'),
            new Pair<TimeSeriesPeriod>(TimeSeriesPeriod.Infinity, 'All'),
        ];
        this.dialogService = dialogService;
        this.errorService = errorService;
        this.configuration = new Configuration();
        this.configuration.labels = ['inbound', 'outbound'];
    }

    async attached() {
        window.addEventListener('resize', this.resize);
        this.subscription = this.eventAggregator.subscribe('tab', this.resize);
        await this.load(TimeSeriesPeriod.OneYear);
        this.updateScale();
        this.updateGraph();
        await this.loadPage();
    }

    detached() {
        window.removeEventListener('resize', this.resize);
        this.subscription.dispose();
    }

    measureText(text: string, fontStyle: string) {
        const element = document.createElement('canvas');
        const context = element.getContext('2d')!;

        context.font = fontStyle;
        const size = {
            width: context.measureText(text).width,
            height: parseInt(context.font),
        };

        return size;
    }

    async loadTransactionHistory(view: string) {
        const response: any = await this.httpClient.get(view);
        const json = await response.json();
        const items = json.items.map((m) => {
            m.timestamp = new Date(m.timestamp);
            return m;
        });

        this.payload = json;
        this.items = items;
        this.filtered = items;
        this.configuration.data = items;
        this.updateScale();
        this.updateGraph();

        if (this.items.length !== 0) {
            this.windowFromTo.from = this.items[0].timestamp;
            this.windowFromTo.to = this.items[this.items.length - 1].timestamp;
            await this.loadPage();
        } else {
            this.transactions = [];
            this.page = 0;
            this.of = 1;
        }
    }

    async changePage(page: number) {
        await this.loadPage(page);
    }

    async loadPage(page: number = 1) {
        if (this.items.length === 0) {
            return;
        }

        const response: any = await this.httpClient.get(
            `customers/${this.customerId}/transactions/list?from=${this.windowFromTo.from.toISOString()}&to=${this.windowFromTo.to.toISOString()}&skip=${(page - 1) * 15}`,
        );
        const json = await response.json();
        const list = json.list.map((m) => {
            if (m.amount.value >= 0) {
                m.cashFlow = 'Inbound';
            } else {
                m.cashFlow = 'Outbound';
            }
            m.booked = new Date(m.booked);

            return m;
        });

        this.page = 1;
        this.of = Math.ceil(json.total / 15);
        this.total = json.total;
        this.transactions = list;
    }

    async load(period: TimeSeriesPeriod) {
        this.line = null;
        await this.loadTransactionHistory(`customers/${this.customerId}/transactions/period/${period}`);
        this.selectedPeriod = period;
    }

    resize = async () => {
        // defer execution hack
        await new Promise((f) => setTimeout(f, 0));
        this.line = null;
        this.updateScale();
        this.updateGraph();
    };

    mouseleave = () => {
        this.showHover = false;
        this.line = null;
    };

    mousemove($event: MouseEvent) {
        this.drag.updateGraph($event);
        this.showHover = this.filtered.length !== 0;

        if (this.hover.clientWidth + $event.offsetX + 10 >= this.canvas.clientWidth) {
            this.cursorX = this.canvas.clientWidth - this.hover.offsetWidth;
        } else {
            this.cursorX = $event.offsetX + 10;
        }

        this.cursorY = $event.offsetY + 10;
    }

    mousedown($event: MouseEvent) {
        this.line = null;
        this.rect = new Rect();
        this.drag = new DragZoom($event, this.rect, this);
    }

    mouseup($event: MouseEvent) {
        this.drag.complete($event);
        this.rect = null;
        this.drag = new DragNone(this.line, this);
    }

    async reset() {
        this.filtered = this.items;
        this.updateScale();
        this.updateGraph();
        this.windowFromTo.reset();

        if (this.items.length !== 0) {
            this.windowFromTo.from = this.items[0].timestamp;
        }

        await this.loadPage();
    }

    findMax(input: number): number {
        if (isNaN(input)) {
            return 10;
        }

        if (input < 100) {
            input = 100;
        }

        const digits = Math.ceil(Math.log10(input));
        const upper = Math.pow(10, digits);
        const step = upper / 10;
        let scale = upper;

        while (scale - step >= input) {
            scale -= step;
        }

        return scale;
    }

    updateScale() {
        let converter = new NumbersValueConverter();
        this.min = 0;
        const range = Math.max(...[...this.filtered.map((x) => x.inbound), ...this.filtered.map((x) => x.outbound)]);
        const max = this.findMax(range);
        const points = 4;
        let array: Guide[] = [];

        this.scale = this.height / max;

        for (let i = 1; i < points; ++i) {
            const value = max * (points - i) * 0.25;
            let guide = new Guide();

            guide.text = `${converter.toView(Math.round(value))}`;
            guide.y = (this.height * i) / points;
            array.push(guide);
        }

        this.guides = array;
    }

    div(dividend: number, divisor: number) {
        if (divisor === 0) {
            return 0;
        }

        return dividend / divisor;
    }

    updateGraph() {
        this.width = this.canvas.clientWidth;
        this.height = this.canvas.clientHeight;

        let height = this.height - 20;
        let items = this.filtered.length;
        let resolution = (this.width - this.offsetGraph) / items;

        this.sum = this.filtered.reduce((acc, x) => acc + x.inbound, 0);
        this.sum2 = this.filtered.reduce((acc, x) => acc + x.outbound, 0);
        this.count = this.filtered.reduce((acc) => acc + 1, 0);

        this.avr = this.div(this.sum, this.filtered.length);
        this.avr2 = this.div(this.sum2, this.filtered.length);

        let columns: Column[] = [];
        const configuration = this.configuration;
        const barWidth = (resolution * 0.75) / configuration.labels.length;

        this.filtered.map((x, ix) => {
            const inbound = x.inbound || 0;
            const outbound = x.outbound || 0;
            const remaining = (resolution * 0.25) / 2;
            let values: Bar[] = [
                new Bar(
                    this.offsetGraph + remaining,
                    height + 9 - ((inbound - this.min) * this.scale - this.margin),
                    configuration.colors[2],
                ),
                new Bar(
                    this.offsetGraph + remaining + barWidth * 1,
                    height + 9 - ((outbound - this.min) * this.scale - this.margin),
                    configuration.colors[3],
                ),
            ];
            let column = new Column(ix * resolution, values, new Date('' + x.timestamp).toLocaleDateString('se-SV')); //

            column.width = barWidth;
            columns.push(column);
        });

        this.columns = columns;
        this.resolution = resolution;

        const buildLineChart = (list: Sample[], field: (sample: Sample) => number): string => {
            let array: string[] = [];

            list.forEach((x, ix) => {
                const value = field(x);

                if (value === null || value === 0) {
                    return;
                }

                array.push(
                    `${this.offsetGraph + ix * resolution + resolution / 2},${height + 10 - ((value - this.min) * this.scale - this.margin)}`,
                );
            });

            return array.join(' ');
        };

        this.inbound = buildLineChart(this.filtered, (x) => x.inbound);
        this.outbound = buildLineChart(this.filtered, (x) => x.outbound);
    }

    async loadBars() {
        let response: any = await this.httpClient.get(
            `customers/${this.customerId}/transactions/bars?from=${this.windowFromTo.from.toISOString()}&to=${this.windowFromTo.to.toISOString()}`,
        );
        let json = await response.json();
        let items = json.items.map((m) => {
            m.timestamp = new Date(m.timestamp);
            return m;
        });

        this.payload = json;
        this.filtered = items;
        this.configuration.data = items;
        this.updateScale();
        this.updateGraph();
        this.page = 0;
        this.of = 1;
        this.total = 0;
        await this.loadPage();
    }

    async slice(begin: number, end: number) {
        if (begin > end) {
            this.reset();
            return;
        }

        if (begin < this.offsetGraph) {
            begin = 0;
        } else {
            begin -= this.offsetGraph;
        }

        end -= this.offsetGraph;

        const count = this.filtered.length;
        const width = this.width - this.offsetGraph;
        const resolution = width / count;
        const from = Math.floor(begin / resolution) - 1;
        const to = Math.floor(end / resolution);

        if (from >= 0) {
            this.windowFromTo.from = this.filtered[from].timestamp;
        } else {
            this.windowFromTo.from = this.filtered[0].timestamp;
        }

        if (to < count) {
            this.windowFromTo.to = this.filtered[to].timestamp;
        } else {
            this.windowFromTo.to = this.filtered[this.filtered.length - 1].timestamp;
        }

        await this.loadBars();
        this.line = null;
        this.active = null;
        this.showHover = false;
    }

    over(x: number) {
        if (x < this.offsetGraph) {
            this.line = null;
            return;
        }

        const cursorX = x - this.offsetGraph;
        const count = this.filtered.length;
        const width = this.width - this.offsetGraph;
        const resolution = width / count;
        const position = this.offsetGraph + Math.floor(cursorX / resolution) * resolution;
        const height = this.canvas.clientHeight;
        const active = this.filtered[Math.floor(cursorX / resolution)] || new Sample();

        this.line = new Line();
        this.line.x = position;
        this.active = active;
        this.y1 = height - this.margin - (active.inbound - this.min) * this.scale;
        this.y2 = height - this.margin - (active.outbound - this.min) * this.scale;
    }

    showTransaction(transaction: Transaction) {
        try {
            this.dialogService
                .open({
                    viewModel: TransactionDialog,
                    model: transaction,
                    lock: false,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                    }
                });
        } catch (error) {
            this.errorService.showErrorDialog(error.message);
        }
    }
}

interface Slice {
    slice(begin: number, end: number);
}

interface Over {
    over(x: number);
}

class Guide {
    text: string;
    y: number;
}

class Bar {
    offset: number;
    value: number;
    color: string;

    constructor(offset: number, value: number, color: string) {
        this.offset = offset;
        this.value = value;
        this.color = color;
    }
}

class Column {
    x: number;
    width: number = 12;
    bars: Bar[] = [];
    label: string;

    constructor(x: number, bars: Bar[], label: string) {
        this.x = x;
        this.bars = bars;
        this.label = label;
    }
}

class Configuration {
    labels: string[] = [];
    colors: string[] = ['#277414', '#DC2626', '#3B72FF', '#495057'];
    data: any[] = [];
}

class WindowFromTo {
    from: Date;
    to: Date;

    constructor() {
        this.reset();
    }

    reset() {
        this.from = new Date();
        this.to = new Date();
    }
}

class Transaction {}

class Rect {
    x1: number;
    x2: number;
}

class Line {
    x: number = -20;
}

enum TimeSeriesPeriod {
    Today = 'Today',
    OneWeek = 'OneWeek',
    OneMonth = 'OneMonth',
    ThreeMonths = 'ThreeMonths',
    OneYear = 'OneYear',
    ThreeYears = 'ThreeYears',
    Infinity = 'Infinity',
}

class Pair<T> {
    key: T;
    text: string;

    constructor(key: T, text: string) {
        this.key = key;
        this.text = text;
    }
}

class Payload {
    items: Sample[];
}

class Sample {
    inbound: number;
    outbound: number;
    timestamp: Date;
}

abstract class DragBase {
    abstract updateGraph($event: MouseEvent);
    abstract complete($event: MouseEvent);
}

class DragNone extends DragBase {
    line: Line | null;
    over: Over;

    constructor(line: Line | null, over: Over) {
        super();
        this.over = over;
        this.line = line;
    }

    updateGraph($event: MouseEvent) {
        this.over.over($event.offsetX);
    }

    complete($event: MouseEvent) {}
}

class DragZoom extends DragBase {
    rect: Rect;
    begin: number;
    slice: Slice;

    constructor($event: MouseEvent, rect: Rect, slice: Slice) {
        super();
        this.slice = slice;
        this.rect = rect;
        this.begin = $event.offsetX;
    }

    updateGraph($event: MouseEvent) {
        let min = Math.min(this.begin, $event.offsetX);
        let max = Math.max(this.begin, $event.offsetX);

        this.rect.x1 = min;
        this.rect.x2 = max;
    }

    complete($event: MouseEvent) {
        if ($event.offsetX == this.begin) {
            return;
        }

        this.slice.slice(this.begin, $event.offsetX);
    }
}

enum GraphStyle {
    Bar = 'Bar',
    Line = 'Line',
}

class Chart {
    constructor(
        public style: GraphStyle,
        public icon: string,
    ) {}
}
